<template>
  <section class="account-projects">
    <app-spinner v-if="isLoading" />
    <div v-else-if="projectDrafts && projectDrafts.length" class="container">
      <article class="project-draft card-content" v-for="(projectDraft) in projectDrafts" :key="projectDraft.projectDraftId">
          <div v-if="!projectDraft.isInBuildingArea" class="nba-text-container">
            <p>Contactez-nous pour finaliser votre projet</p>
            <p>Pour en savoir plus : <a href="mailto:accueil@group-alysia.com">accueil@group-alysia.com</a></p>
          </div>
          <div class="grid-x" :class="{'no-building-area' : !projectDraft.isInBuildingArea}" @click="pushToProject(projectDraft)">
            <div class="cell large-shrink medium-12 small-12">
              <img v-if="projectDraft.version && projectDraft.version.versionMedia" :src="projectDraft.version.versionMedia" />
            </div>
            <div class="cell large-auto medium-12 small-12">
              <div class="content-body">
                <p class="label">Version <span>{{ projectDraft.version.model.name }}</span></p>
                <h3  v-if="projectDraft.version">Maison {{ projectDraft.version.numberOfRooms }} chambre{{ projectDraft.version.numberOfRooms > 1 ? 's' : '' }}{{ projectDraft.totalSurface ? ` - ${Math.round(projectDraft.totalSurface)} m²` : '' }}</h3>
                <p class="infos">{{ projectDraft.customerLand && projectDraft.customerLand.address ? projectDraft.customerLand.address.city : '' }}</p>
                <p class="infos">{{ projectDraft.land && projectDraft.land.address && projectDraft.land.address.postalCodeInseeNumber ? projectDraft.land.address.city : '' }}</p>

                <div class="land-infos">
                  <small v-if="projectDraft.land && projectDraft.land.allotment && projectDraft.land.allotment.allotmentId">
                    * {{ `Lotissement ${projectDraft.land.allotment.name}${projectDraft.land.lotNumber ? ` - Lot n°${projectDraft.land.lotNumber}` : ''} - ${projectDraft.land.surface} m²` }}
                  </small>
                  <small v-else-if="projectDraft.land && projectDraft.land.surface">
                    * Sur la base d'un  terrain de {{ projectDraft.land.surface }}m² à {{ utils.formatCentToEuro(projectDraft.landPrice, true, true) }}
                  </small>
                  <small v-else-if="projectDraft.customerLand && projectDraft.customerLand.surface">
                    * Sur la base d'un  terrain de {{ projectDraft.customerLand.surface }}m² à {{ utils.formatCentToEuro(projectDraft.landPrice, true, true) }}
                  </small>
                </div>

                <div class="btn-container">
                  <div>
                    <app-button class="cta download-pdf" v-if="projectDraftsAgencies && projectDraftsAgencies.length" :disabled="!projectDraft.isInBuildingArea || isPDFLoading" @click.stop.prevent="(e) => downloadProjectPdf(projectDraft)" size="small" icon="document">Télécharger le descriptif PDF</app-button>
                  </div>
                  <div>
                    <span v-if="projectDraft.agencyName" class="cta" @click.stop.prevent="handleAgencyClick(agencyLink(projectDraft))">
                      <app-button size="small" theme="secondary" class="contact-agency">Contacter l'agence</app-button>
                    </span>
                  </div>
                </div>
                <div class="limit-date" v-if="projectDraft.status !== 'REQUESTED' && projectDraft.status !== 'VALIDATED'">Ce projet sera sauvegardé jusqu'au {{ $dayjs(projectDraft.createdAt).add(12, 'M').format('DD MMMM YYYY')}}</div>
              </div>
            </div>
            <div class="cell large-shrink medium-12 small-12">
              <div class="price" v-if="projectDraft.isInBuildingArea">
                <h3>{{ utils.formatCentToEuro(projectDraft.totalPrice, true, true) }}</h3>
              </div>
            </div>
          </div>
          <div class="grid-x bottom">
            <div class="cell auto">
              <p class="project-name">{{ projectDraft.name }}</p>
            </div>
            <div class="cell shrink">
              <div class="delete-container cta" @click.stop.prevent="() => deletedProjectDraft(projectDraft)">
                <bin />
                <span class="infos">Supprimer</span>
              </div>
            </div>
          </div>
      </article>
      <div class="note">À noter : Les avant-projets sont sauvegardés 12 mois.</div>
    </div>
    <div v-else class="empty-list">
      <div class="row">
        <p>Vous n'avez pas encore de projets</p>
      </div>
      <router-link :to="{ name: 'home' }">
        <app-button theme="primary">Configurer maintenant</app-button>
      </router-link>
    </div>
  </section>
</template>

<script>
import projectDraftApi from '@/services/api/projectDraft';
import customerApi from '@/services/api/customer';
import auth from '@/services/auth';
import utils from '@/services/utils/utils';
import departements from '@/services/data/departements.json';
import agencyApi from '@/services/api/agency';

import bin from '@/assets/img/remove-bin.svg?inline';
import slugify from 'slugify';

export default {
  components: {
    bin,
  },
  data() {
    return {
      isPDFLoading: false,
      isLoading: true,
      projectDrafts: null,
      projectDraftsAgencies: null,
      utils,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getProjectDrafts();
    this.isLoading = false;
    await this.getProjectDraftsAgencies();
  },
  methods: {
    handleAgencyClick(link) {
      window.location.href = link;
    },
    async downloadProjectPdf(projectDraft) {
      this.isPDFLoading = true;
      try {
        const response = await customerApi.getProjectPdf(projectDraft.projectDraftId, auth.getToken());
        const pdf = new Blob([response]);

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdf);
        link.download = `Maison-Alysia_Notice_${projectDraft.customer.reference}_${this.$dayjs(
          new Date(),
        ).format('DD-MM-YYYY')}.pdf`;
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'La génération du PDF a rencontré un problème, veuillez nous excuser pour ce dérangement, nous vous invitons à réessayer plus tard',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isPDFLoading = false;
    },
    pushToProject(projectDraft) {
      if (projectDraft.isInBuildingArea) {
        this.$router.push({ name: 'project-projectId', params: { projectDraftId: projectDraft.projectDraftId, tab: 'version' } });
      }
    },
    async getProjectDraftsAgencies() {
      try {
        this.projectDraftsAgencies = await Promise.all(this.projectDrafts.map((project) => {
          if (!(project.land && project.land.address && project.land.address.postalCodeInseeNumber) && !(project.customerLand && project.customerLand.address && project.customerLand.address.postalCodeInseeNumber)) {
            return null;
          }
          const postalCode = project.land.address.postalCodeInseeNumber || project.customerLand.address.postalCodeInseeNumber;
          return agencyApi.getByPostalCode(postalCode);
        }));
      } catch (er) {
        if (er.response && er.response.status === 401) {
          this.$notification.show({ text: 'Votre session n\' est plus valide !' });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération des projets',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async getProjectDrafts() {
      try {
        this.projectDrafts = await projectDraftApi.getAll();
      } catch (er) {
        if (er.response && er.response.status === 401) {
          this.$notification.show({ text: 'Votre session n\' est plus valide !' });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération des projets',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async deletedProjectDraft(projectDraft) {
      try {
        this.$message.show({
          title: 'Avertissement',
          text: 'Êtes-vous sûr de vouloir supprimer ce projet ?',
          confirmText: 'Continuer',
          hasConfirm: true,
          cancelText: 'Annuler',
          hasCancel: true,
          onConfirm: async () => {
            this.isLoading = true;
            await projectDraftApi.delete(projectDraft.projectDraftId);
            this.getProjectDrafts();
            this.isLoading = false;
          },
        });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du projets',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    /* async downloadProjectPdf(projectDraft) {
      const baseServer = process.env.VUE_APP_API_URL;
      const query = `?token=${auth.getToken()}`;
      const route = `/customers/me/project-drafts/${projectDraft.projectDraftId}/pdf`;
      window.open(baseServer + route + query, '_blank');
    }, */
    agencyLink(projectDraft) {
      const agencySlug = slugify(projectDraft.agencyName, {
        lower: true,
      });
      const departmentCode = projectDraft.land.address.postalCodeInseeNumber.slice(0, 2);
      const department = departements.find((dep) => dep.code === departmentCode);
      return `/agences/${department.slug.replaceAll(' ', '-')}/${agencySlug}`;
    },
  },
};

</script>

<style lang="sass">
.account-projects
  .note
    text-align: center
    font-size: 12px
    color: $black
  .row
    @include row
  a
    text-decoration: none
  .app-spinner
    margin: 50px auto
  .project-draft
    overflow: hidden
    @media screen and (max-width: 768px)
      > a
        pointer-events: none
        .cta
          pointer-events: all
    &.card-content
      @include card-content
      padding: 0
      max-width: 650px
      margin: 2rem auto
      position: relative
      box-shadow: 0px 0px 1.4px rgba(0, 0, 0, 0.024), 0px 0px 3.8px rgba(0, 0, 0, 0.035), 0px 0px 9px rgba(0, 0, 0, 0.046), 0px 0px 30px rgba(0, 0, 0, 0.07)
      .grid-x:first-child .cell:first-child
        height: 220px
      .grid-x
        cursor: pointer
        &.bottom
          cursor: auto
      .no-building-area
        opacity: 0.2
        cursor: default!important
      .nba-text-container
        width: 80%
        z-index: 10
        top: 40%
        left: 50%
        position: absolute
        display: flex
        flex-flow: column
        justify-content: center
        align-items: center
        transform: translate(-50%, -50%)
        p:first-child
          text-align: center
          font-size: 1.3rem
          color: $primary
          font-weight: bold
        p:nth-child(2)
          margin-top: 1rem
          color: #333
          font-weight: bold
    img
      width: 270px
      height: 220px
      max-height: 300px
      object-fit: cover
      @include breakpoint(large down)
        width: 100%
    .content-body
      padding: 18px 24px
      height: 100%
      .limit-date
        font-size: 12px
        color: $black
        margin-top: 8px
        @media screen and (min-width: 1200px)
          position: absolute
          bottom: 20%
      .label
        @include label
        color: $body-color
        > span
          text-transform: uppercase
          font-size: inherit
          line-height: inherit
          color: inherit
          font-weight: inherit
      h3
        @include body-bold
        color: $black
        margin: 0
      .infos
        @include infos
        color: $body-color
      .land-infos
        margin-top: 0.5rem
        line-height: 1rem
      .contact-agency
        color: $body-color
      .btn-container
        margin-top: 1rem
        display: flex
        flex-direction: column
        flex-wrap: wrap
        gap: 0.75rem
        @media screen and (max-width: 768px)
          flex-direction: row
    .bottom
      background-color: rgba($black, 0.7)
      padding: 12px 24px
      p
        color: white
      .project-name
        @include body-bold
        color: white
      .infos
        color: white
      svg
        height: 14px
        margin-right: 10px
      .delete-container
        display: flex
        height: 100%
        align-items: center
        cursor: pointer
        span
          height: min-content
    .download-pdf
      .img
        filter: brightness(400%) !important
      .app-spinner
        margin: 0 70px
        span
          border-color: $white
    .price
      background-color: $warning
      padding: 12px 10px
      width: fit-content
      padding: 5px
      margin: 0 16px 0 0
      margin-top: 30px
      border-radius: 16px
      float: right
      @include breakpoint(large down)
        margin: 0
        float: initial
        margin-left: 24px
        margin-bottom: 24px
      h3
        @include body-bold
        color: white
        font-weight: 500
        margin: 0px 5px
  .empty-list
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    p
      text-align: center
      margin-top: 50px
</style>
