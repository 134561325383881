<template>
  <section class="account-password">
    <app-error v-if="errors.length" title="Erreur" :messages="errors" @click="errors = []"></app-error>
    <div class="card-content">
      <h3>Mot de passe</h3>
      <form class="grid-x gid-margin-x grid-margin-y" ref="passwordForm">
        <div class="cell stretch">
          <app-input label="Mot de passe actuel" required v-model="currentPassword" type="password" placeholder="Mot de passe actuel ..."></app-input>
          </div>
        <div class="cell stretch">
          <app-input label="Nouveau mot de passe" required v-model="newPassword" type="password" placeholder="Nouveau mot de passe ..." :minlength="8"></app-input>
        </div>
        <div class="cell stretch">
          <app-input label="Répétez nouveau mot de passe" required v-model="confirmationPassword" type="password" ref="confirmationPassword" placeholder="Répétez le nouveau mot de passe ..." :minlength="8"></app-input>
        </div>
      </form>
      <app-button @click="submitForm" :disabled="loading">Modifier mot de passe</app-button>
    </div>
  </section>
</template>

<script>
import customerApi from '@/services/api/customer';

export default {
  data() {
    return {
      loading: false,
      errors: [],
      currentPassword: '',
      newPassword: '',
      confirmationPassword: '',
    };
  },
  watch: {
    confirmationPassword() {
      this.validateConfirmationPassword();
    },
    newPassword() {
      this.validateConfirmationPassword();
    },
  },
  methods: {
    validateConfirmationPassword() {
      const input = this.$refs.confirmationPassword.$el.querySelector('input');
      if (this.confirmationPassword !== this.newPassword) {
        input.setCustomValidity('Le nouveau mot de passe et la répétition du mot de passe sont différents');
      } else {
        input.setCustomValidity('');
      }
    },
    submitForm() {
      this.errors = [];
      if (this.$refs.passwordForm.reportValidity()) {
        this.editPassword();
      }
    },
    async editPassword() {
      this.loading = true;
      try {
        await customerApi.editPassword(this.currentPassword, this.newPassword);
        this.$notification.show({ text: 'Mot de passe changé avec succès' });
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmationPassword = '';
      } catch (error) {
        this.errors.push('Mot de passe actuel incorrect');
      }
      this.loading = false;
    },
  },
};

</script>

<style lang="sass">
.account-password
  .app-error
    margin-bottom: 2rem
  .card-content
    @include card-content
    width: fit-content
    margin: auto
    > .app-button
      margin-top: 2rem
</style>
