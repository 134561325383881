<template>
  <section class="account-settings">
    <app-spinner v-if="loading" />
    <app-error v-else-if="errors.length" title="Erreur" :messages="errors" @click="errors = []"></app-error>
    <template v-else>
      <form class="card-content personal-info-form" ref="personalInfoForm">
        <h3>Informations personnelles</h3>
        <div class="grid-x grid-margin-x grid-margin-y">
          <div class="cell medium-6">
            <app-input label="Prénom" required v-model="customer.firstname" placeholder="Prénom ..."></app-input>
            </div>
          <div class="cell medium-6">
            <app-input label="Nom" required v-model="customer.lastname" placeholder="Nom ..."></app-input>
          </div>
          <div class="cell medium-6">
            <app-input label="Numéro de téléphone" required v-model="customer.phone" type="phone" placeholder="Téléphone ..." :minlength="10"></app-input>
          </div>
          <div class="cell medium-6">
            <app-input label="Email" required disabled v-model="customer.email" type="email" placeholder="Email ..."></app-input>
          </div>
        </div>
        <app-button @click="submitForm">Enregistrer les modifications</app-button>
      </form>
      <div class="card-content password-container">
        <h3>Sécurité</h3>
        <div class="grid-x grid-margin-x grid-margin-y align-bottom">
          <div class="cell auto">
            <app-input label="Mot de passe" disabled type="password" value="••••••••••••" placeholder="Mot de passe ..."></app-input>
          </div>
          <div class="cell medium-shrink">
            <app-button @click="$router.push({ ...$route, params: { tab: 'mot-de-passe' }})" size="small">Modifier mot de passe</app-button>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import customerApi from '@/services/api/customer';
import authApi from '@/services/auth';

export default {
  data() {
    return {
      loading: false,
      customer: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
      },
      errors: [],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      this.loading = true;
      try {
        const response = await customerApi.getMe();
        this.customer = response;
      } catch (error) {
        authApi.logout();
        // Met à jour la connexion sur le composant App
        this.$root.$children[0].updateIsLoggedIn();
      }
      this.loading = false;
    },
    submitForm() {
      this.errors = [];
      if (this.$refs.personalInfoForm.reportValidity()) {
        this.editMe();
      }
    },
    async editMe() {
      this.loading = true;
      try {
        const response = await customerApi.editMe(this.customer);
        this.customer = response;
        this.$notification.show({ text: 'Informations mises à jour avec succès' });
      } catch (error) {
        this.errors.push('Impossible d\'éditer vos informations personnelles.');
      }
      this.loading = false;
    },
  },
};

</script>

<style lang="sass">
.account-settings
  .app-spinner
    margin: 50px auto
  .app-error
    margin-bottom: 2rem
  .card-content
    @include card-content
    width: 650px
    margin: 2rem auto
    > .app-button
      width: fit-content
      margin: 2rem auto 0 auto
    .app-input
      input:disabled
        background-color: $medium-bg
        border-color: $line
        color: $body-color
  @media (max-width: 768px)
    .card-content
      width: unset
      .app-button
        width: 100%
</style>
