<template>
  <section class="account">
    <header class="hero-small">
      <div>
        <template v-if="isPasswordTab">
          <router-link :to="{ ...$route, params: { tab: 'parametres' }}"><ReturnArrowSvg />Retour au compte</router-link>
          <h1>Sécurité</h1>
        </template>
        <h1 v-else>
          Mon compte
        </h1>
      </div>
      <app-button class="logout" @click="logout" theme="secondary">Se déconnecter</app-button>
    </header>
    <main class="grid-container">
      <AccountPassword v-if="isPasswordTab" />
      <div v-else class="tabs-container">
        <router-link :to="{ ...$route, params: { tab: 'mes-projets' } }">Mes projets</router-link>
        <router-link :to="{ ...$route, params: { tab: 'recherche' } }">Configurer</router-link>
        <router-link :to="{ ...$route, params: { tab: 'parametres' } }">Paramètres</router-link>
      </div>
      <AccountSettings v-if="isSettingsTab" />
      <AccountProjects v-if="isProjectsTab" />
      <AccountSearch v-if="isSearchTab" />
    </main>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import ReturnArrowSvg from '@/assets/img/return-arrow.svg?inline';

import AccountPassword from './AccountPassword.vue';
import AccountSettings from './AccountSettings.vue';
import AccountProjects from './AccountProjects.vue';
import AccountSearch from './AccountSearch.vue';

export default {
  components: {
    ReturnArrowSvg,
    AccountPassword,
    AccountSettings,
    AccountProjects,
    AccountSearch,
  },
  data() {
    return {
    };
  },
  computed: {
    isPasswordTab() {
      return this.$route.params.tab === 'mot-de-passe';
    },
    isSettingsTab() {
      return this.$route.params.tab === 'parametres';
    },
    isProjectsTab() {
      return this.$route.params.tab === 'mes-projets';
    },
    isSearchTab() {
      return this.$route.params.tab === 'recherche';
    },
  },
  methods: {
    async logout() {
      try {
        await authApi.logout();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la déconnection',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};

</script>

<style lang="sass">
.account
  background-color: $medium-bg
  min-height: 100vh
  .hero-small
    @include hero-small
    height: 10vh
    min-height: 200px
    background-image: url(~@/assets/img/pages/account-hero.jpeg)
    padding-top: 70px
    a
      color: $white
      svg
        width: 12px
        margin-right: 0.5rem
        path
          stroke: currentcolor
  main
    padding-top: 2rem
    padding-bottom: 2rem
    .tabs-container
      text-align: center
      a
        margin: 0 1rem
        text-decoration: none
        padding-bottom: 3px
        &.router-link-active
          color: white
          background-color: $primary
          border-radius: 32px
          padding: 8px 16px
    .card-content > h3
      margin-bottom: 1.5rem
  @media (max-width: 768px)
    .hero-small
      height: 30vh
  .logout
    margin-top: 16px
    margin-bottom: 16px
</style>
