<template>
  <section class="account-search">
    <!-- Entrée pour le configurateur  -->
    <div class="configurator-search-container">
      <configurator-search />
    </div>
  </section>
</template>

<script>
import ConfiguratorSearch from '@/components/general/ConfiguratorSearch.vue';

export default {
  components: {
    ConfiguratorSearch,
  },
};
</script>

<style lang="sass">
.account-search
  margin: 3rem auto
  .configurator-search-container
    @media (min-width: 768px)
      width: 85%
      margin: 0 auto
</style>
